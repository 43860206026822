body{
  background-color:#fff;
}
.beacon{
  /*position:absolute;*/
  /*top:60%;*/
  /*right:7%;*/
  background-color: #f13d3d;
  box-shadow: 0px 2px 5px #666;
  height:1.0em;
  width:1.0em;
  border-radius:50%;
  -webkit-animation: grow .4s 1 linear;
  animation: grow .4s 1 linear;
  -webkit-transform:translateX(-50%) translateY(-50%);
}
.beacon:before{
  position:absolute;
  content:"";
  height:1.0em;
  width:1.0em;
  left:0;
  top:0;
  background-color:transparent;
  border-radius:50%;
  box-shadow:0px 0px 2px 2px #f13d3d;
  -webkit-animation:active 2s infinite linear;
  animation:active 2s infinite linear;
}

@-webkit-keyframes grow {
  0% {
    -webkit-transform: scale(.1);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes grow {
  0% {
    transform: scale(.1);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes active{
  0%{
    -webkit-transform:scale(.1);
    opacity:1;
  }
  70%{
    -webkit-transform:scale(2);
    opacity:0;
  }
  100%{
    opacity:0;
  }
}

@keyframes active{
  0%{
    transform:scale(.1);
    opacity:1;
  }
  70%{
    transform:scale(2);
    opacity:0;
  }
  100%{
    opacity:0;
  }
}
