body {
    background-color: #fff;
}

.beacon-point .MuiSvgIcon-fontSizeMedium, .beacon-point-grey .MuiSvgIcon-fontSizeMedium {
    /*color: blue;*/
    font-size: 18px;
}
.beacon-point {
    /*position:absolute;*/
    /*top:60%;*/
    /*right:7%;*/
    /*height: 20px;*/
    /*width: 20px;*/
    /*background-color: #f13d3d;*/
    /*box-shadow: 0px 2px 5px #666;*/
    /*border-radius: 50%;*/
    /*-webkit-animation: grow .4s 1 linear;*/
    /*animation: grow .4s 1 linear;*/
    /*-webkit-transform: translateX(-50%) translateY(-50%);*/
}

.beacon-point:before {
    position: absolute;
    content: "";
    left: 3px;
    top: 3px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 2px #f13d3d;
    -webkit-animation: active 2s infinite linear;
    animation: active 2s infinite linear;
    z-index: 0;
}

.beacon-point-grey:before {
    position: absolute;
    content: "";
    left: 5px;
    top: 5px;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 2px #444;
    -webkit-animation: active 2s infinite linear;
    animation: active 2s infinite linear;
    z-index: 0;
}

@-webkit-keyframes grow {
    0% {
        -webkit-transform: scale(.1);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@keyframes grow {
    0% {
        transform: scale(.1);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes active {
    0% {
        -webkit-transform: scale(.1);
        opacity: 1;
    }
    70% {
        -webkit-transform: scale(2);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes active {
    0% {
        transform: scale(.1);
        opacity: 1;
    }
    70% {
        transform: scale(2);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
